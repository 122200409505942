<template>
  <div class="product-card rounded-lg" :class="{ 'shadow-soft': witdShadow }">
    <div class="mdmax:h-auto">
      <slot name="image"></slot>
    </div>
    <div class="p-3 bg-white text-left rounded-b-lg" :class="{ 'border border-grey-field': withBorder }">
      <slot name="description"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    withBorder: {
      type: Boolean,
      default: false
    },
    witdShadow: {
      type: Boolean,
      default: false
    }
  }
}
</script>
